<template>
  <div class="addEventDialog">
    <h1>Neues Event</h1>
    <div class="eventInfo">
      <TextInput v-model:input-text="eventName" input-title="Event-Name">

      </TextInput>
      <TextInput v-model:input-text="description" input-title="Event-Description">

      </TextInput>
      <div class="date">
        <label>Startdatum</label>
        <Datepicker v-model:selected-date="dateFrom" :show-time-picker="false">

        </Datepicker>
      </div>

      <div class="date">
        <label>Enddatum</label>
        <Datepicker v-model:selected-date="dateTo" :show-time-picker="false">

        </Datepicker>
      </div>

    </div>
    <div class="actions">
      <PlainButton button-text="Abbrechen" :button-function="()=>cancelFunction()" button-type="fluid">
        Abbrechen
      </PlainButton>
      <PlainButton :button-function="()=>confirmFunction(updatedEvent)">
        Bestätigen
      </PlainButton>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/Inputs/TextInput";
import PlainButton from "@/components/Buttons/PlainButton";
import Datepicker from "@/components/Inputs/DatePicker";
import {jsonPrepareDate} from "@/helpers";

export default {
  name: "AddEventDialog",
  components: {Datepicker, TextInput,PlainButton},
  props: {
    isUpdate: Boolean,
    confirmFunction:Function,
    cancelFunction:Function,
  },
  data() {
    return {
      eventName: "",
      description: "",
      dateFrom: new Date(),
      dateTo: new Date(),
    }
  },
  computed:{
    updatedEvent(){
      return{
        'event_name':this.eventName,
        'description': this.description,
        'date_from':jsonPrepareDate(this.dateFrom),
        'date_to': jsonPrepareDate(this.dateTo)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "./src/styles/themes";

.addEventDialog {
  box-shadow: 0 5px 20px rgba(0, 0%, 0%, 0.15);
  position: fixed;
  display: flex;
  flex-direction: column;
  padding: 32px;
  align-items: flex-start;
  z-index: 101;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  @include theme() {
    background-color: theme-get('background-color-2');
  }

  border-radius: 16px;

  h1 {
    @include theme() {
      color: theme-get('header-1');
    }

    margin-top: 0;
    line-height: 1.05;
    font-size: 3em;
    font-weight: 600;
  }

  .actions {
    margin-top: 64px;
    display: flex;
    gap: 16px;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .eventInfo{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }
  .date{
    label{
        transition: 0.25s;
        font-size: 1.5em;
        font-weight: 500;
        @include theme() {
          color: theme-get('text-1')
        }
    }
  }

}
@media screen and (max-width: 768px) {
  .addEventDialog{
    width: 512px;
  }


}
@media screen  and (max-width: 512px){

  .addEventDialog{
    align-items: flex-start;

    h1{
      text-align: center;
      font-size: 2em;
    }
    width: 312px;
    .actions {
      margin-top: 32px;
      flex-direction: column-reverse;
    }
  }
}
</style>
