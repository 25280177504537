<template>
  
  <div id="events" >
    <FullscreenLoading v-if="alertType==='alert-loading'">
    </FullscreenLoading>
    <h1>Events</h1>
    <div id="eventAdd">
      <PlainButton :button-function="openAddEventDialog"  scale-by-child is-small button-type="success">
        <div style="display: flex;justify-content: center; align-items: center; line-height: 1; gap:4px">
          <span class="material-icons">add</span>
          Event hinzufügen
        </div>
      </PlainButton>
    </div>
    <div class="eventList">
      <EventListElement v-for="event in events" :event="event" :key="event.event_id" :toggle-event-registration="openEvent">

      </EventListElement>
    </div>
    <transition name="dialog">
    <AddEventDialog v-if="eventDialogVisible" :confirm-function="createNewEvent"  :cancel-function="closeAddEventDialog" ></AddEventDialog>
    </transition>
    <transition name="dialogBackground">
      <div class="dialogBackground"
           v-if="eventDialogVisible"
           @click="()=>{eventDialogVisible=false}"></div>
    </transition>
    <SuccessPop :error-text="alertMessage" v-if="alertType==='alert-success'"></SuccessPop>
    <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {adminService} from "@/services";
import ErrorPop from "@/components/Popups/ErrorPop";
import SuccessPop from "@/components/Popups/SuccessPop";
import PlainButton from "@/components/Buttons/PlainButton";
import EventListElement from "@/components/List/Events/EventListElement";
import AddEventDialog from "@/components/Dialogs/AddEventDialog";

import FullscreenLoading from "@/components/Indicators/Loading/FullscreenLoading";

export default {
  name: "AdminEvents",
  components: {AddEventDialog, EventListElement, PlainButton, ErrorPop, SuccessPop, FullscreenLoading},
  data() {
    return {
      eventDialogVisible:false,
      events: []
    }
  },
  computed: {
    ...mapState('alert', {
          alertMessage: 'message',
          alertType: 'type'
        }
    ),
  },
  created() {
    this.fetchEvents();
  },
  methods: {
    ...mapActions("alert", ['success', "error"]),
    openAddEventDialog(){
      this.eventDialogVisible=true;
    },
    closeAddEventDialog(){
      this.eventDialogVisible=false;
    },
    createNewEvent(event) {
      adminService.addEvent({
        'event_name': event.event_name,
        'description': event.description,
        'date_from': event.date_from,
        'date_to': event.date_to
      }).then(
          event => {
            this.success('Event Added: ' + event.event_name);
            this.closeAddEventDialog()
            this.fetchEvents()
          },
          error => {

            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    },
    fetchEvents() {
      adminService.fetchEvents().then(events => {
        this.events = events
      })
    },
    openEvent(id, isOpen){
      adminService.toggleEvent(id,isOpen).then(
          eventStatus => {
            this.success('Event Status Changed: ' + eventStatus.is_registration_open?'Open':'Closed');
            this.fetchEvents()
          },
          error => {

            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../styles/themes";
.dialogBackground-enter-active, .dialogBackground-leave-active {
  transition: opacity 0.25s;
}
.dialogBackground-enter-from, .dialogBackground-leave-to {
  opacity: 0;
}

.dialog-enter-active, .dialog-leave-active {
  transition: 0.25s ease;
}
.dialog-enter-to, .dialog-leave-from {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(1);
}
.dialog-enter-from, .dialog-leave-to {
  transform-origin: center;
  transform: translateX(-50%) translateY(-50%) scale(0);
}


.dialogBackground {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  background-color: hsla(0, 0%, 0%, 0.5);
  backdrop-filter: blur(50px);
  width: 100%;
  height: 100%;
}
#events {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 64px;
  gap:24px;
  .eventList{
    display: flex;
    flex-direction: column;
    gap:24px;
  }
  #eventAdd {
    gap: 16px;
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
  }
  .eventList{
    width: 100%;
  }

  .textField {
    input {
      width: 200px;
    }

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  h1 {
    font-size: 6em;
    font-weight: 600;
    line-height: 1.05;
    margin:0;
    @include theme {
      color: theme-get('header-1')
    }
  }

  label {
    @include theme {
      color: theme-get('text-1')
    }
  }

  text-align: left;
}

@media screen and (max-width: 768px){
  #events{
    align-items: center;
    h1{
      font-size: 6em;
    }
    padding: 64px 32px;
  }

}

@media screen and (max-width: 512px) {
  #events{
    gap: 16px;
    h1{
      font-size: 4em;
    }
  }
}
</style>
