export function formatDate(datestring) {

    let date=new Date(datestring)
    return date.toLocaleDateString('de-DE');
}

export function formatDateTime(datestring) {
    let date=new Date(datestring)
    return date.toLocaleDateString('de-DE') + ' - ' + date.toLocaleTimeString('de-DE')
}

function padNumber(nr,length){
    let nrString=nr.toString()
    return nrString.padStart(length,'0');
}

export function jsonPrepareDate(date){
    let dateString='';
    dateString+=date.getFullYear();
    dateString+='-'
    dateString+=padNumber(date.getMonth()+1,2)
    dateString+='-'
    dateString+=padNumber(date.getDate(),2)
    return dateString
}


