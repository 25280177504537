<template>
  <div class="textInput" :class="{'error':isInvalid,'valid':doValidation}">
    <label :class="{'focused':focused}">{{ inputTitle }}</label>
    <div id="inputRow">
      <input :id="textFieldType" ref="inputField"
             :type="textFieldType!=='password'?textFieldType:showPassword?'password':'text'" 
             :value="inputText"
             :placeholder="inputPlaceholder" 
             :name="inputName" 
             @input="$emit('update:inputText', $event.target.value); "
             @focus="toggleFocus(true)" 
             @focusout="toggleFocus(false)" 
             maxlength="320"
             :oncopy="preventCopyPaste?'return false':''" 
             :onDrag="preventCopyPaste?'return false':''"
             :class="{'colored':inputType==='colored'}">
      <span v-if="textFieldType==='password'" class="material-icons" @click="togglePasswordVisibility(!showPassword)"
            id="passwordToggle"> {{ showPassword ? 'visibility' : 'visibility_off' }}</span>
    </div>

    <div v-if="textFieldType==='password'&&showPasswordResetLink">
      <router-link to="/resetpassword">Passwort zurücksetzen</router-link>
    </div>


    <div v-if="textFieldType==='password'" id="passwordRequirementContainer">
      <div v-for="password in passwordRequirements" class="passwordRequirement" :key="password.id"
           :class="[password.validationFunction(inputText)?'success':'error']">
        <div v-if="password.validationFunction(inputText)" class="material-icons">check</div>
        <div v-else class="material-icons">clear</div>
        {{ password.helpText }}
      </div>
    </div>
    <div v-if="isInvalid&&passwordRequirements.length===0" class="error" id="errorText">
      <div class="material-icons">report</div>
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    formType: {
      type: String,
      default: ""
    },
    inputTitle: String,
    inputText: String,
    inputPlaceholder: String,
    inputName: String,
    textFieldType: String,
    validationFunction: Function,
    doValidation: Boolean,
    showPasswordResetLink: {
      type: Boolean,
      default: function () {
        return false;
      }
    },
    preventCopyPaste: {
      type: Boolean,
      default: false
    },
    inputType: {
      type: String,
      default: 'normal',
      validator: t => ['normal', 'colored'].includes(t)
    },
    passwordRequirements: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      focused: false,
      isInvalid: false,
      errorText: "",
      showPassword: true,
    }

  },
  methods: {
    toggleFocus(isFocused) {
      this.focused = isFocused;

    },
    togglePasswordVisibility(isShown) {
      this.showPassword = isShown
      this.$refs.inputField.focus()
    },
    validate(text) {
      if (this.doValidation) {
        let validation = this.validationFunction(text);
        this.isInvalid = validation.isInvalid;
        this.errorText = validation.errorText;
      }
    },
  },
  watch: {
    inputText(text) {
      this.validate(text);
    }
  },
  emits: ['update:inputText']
}
</script>

<style lang="scss" scoped>
@import "../../styles/themes.scss";

.textInput {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.valid {
  margin-bottom: 32px;
}

#passwordToggle {
  cursor: pointer;
  @include theme {
    color: theme-get("text-1")
  }
}

#inputRow {
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px
}

#passwordRequirementContainer {
  @include theme {
    color: theme-get("text-1")
  }
  padding-top: 4px;
  text-align: start;
  font-weight: 500;
  font-size: 0.875em;

  .passwordRequirement {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.success {
  &.passwordRequirement {
    color: #42b983;
  }
}


.error {

  #errorText {
    display: flex;
    align-items: center;
    position: absolute;
    color: #D55353;

    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 0.875em;
    bottom: -2em;

  }

  &.passwordRequirement {
    color: #D55353;
  }

  label {
    color: #D55353 !important;
  }

  input {
    outline: 3px solid #D55353 !important;

    &:focus {
      outline: 3px solid #D55353 !important;
    }
  }
}

label {
  transition: 0.25s;
  font-weight: 500;
  font-size: 1.5em;
  font-family: 'Poppins', sans-serif;
  @include theme() {
    color: theme-get('text-1')
  }

  &.focused {
    @include theme() {
      color: theme-get('primary-color')
    }
  }
}

input {
  box-sizing: border-box;
  position: relative;
  border: none;
  padding: 12px 16px;
  font-size: 1em;
  font-family: 'Poppins', sans-serif;
  border-radius: 6px;
  width: 100%;
  transition: outline-color 0.25s;
  outline: 2px solid rgba(0, 0, 0, 0);
  @include theme {
    color: theme-get('text-2');
    background-color: theme-get('input-background');
    box-shadow: theme-get('shadow-1');
  }

  &:focus {

    @include theme() {
      outline: 3px solid theme-get('primary-color');
    }

  }
}

.colored {
  @include theme {
    color: theme-get('text-2') !important;
    background-color: theme-get('primary-background') !important;
    box-shadow: none;
  }
}


@media screen and (max-width: 768px) {
  label {
    font-size: 1.25em;
  }
}


</style>
