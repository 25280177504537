<template>
  <div id="loadingIndicator">
  </div>
</template>

<script>
export default {
  name: "LoadingIndicator"
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes";
  @keyframes loading {
    0%{
      left: 0;
      right: calc(100% - 32px);
      width: 32px;
    }
    50%{
      left:0;
      width: 100%;
      right: 0;
    }
    100%{
      left: calc(100% - 32px);
      width: 32px;
      right: 0;
    }
  }
  #loadingIndicator{
    position: relative;
    border-radius: 8px;
    height: 32px;
    width: 96px;
    @include theme {
      background-color: theme-get('primary-background');
    }
    left: 50%;
    top:50%;
    transform: translate(-50%,-50%);


    &:after{
      animation: loading cubic-bezier(0.5,0,0.5,1) 0.75s infinite alternate;
      content: "";
      @include theme {
        background-color: theme-get('primary-color');
      }

      height: 32px;
      width: 32px;
      border-radius: 8px;
      position: absolute;
    }
  }
</style>
