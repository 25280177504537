<link rel="stylesheet" href="../../../styles/themes.scss">
<template>
  <div class="eventListElement" @click="$router.push('/admin/events/'+event.event_id)">
    <div class="eventInfo">
      <h3>{{ event.event_name }}</h3>
      <div class="details">
        <p>Turniere: {{ event.tournaments.length }}</p>
        <div class="dot">

        </div>
        <p>Zeitraum: {{
          formattedDateRangeString
          }}
        </p>
      </div>
    </div>
  </div>

</template>

<script>

import {formatDate} from "@/helpers";
export default {
  name: "EventListElement",
  props: {
    event: Object,
    toggleEventRegistration:Function
  },
  computed: {
    hasOpenTournaments() {
      return this.event.tournaments.map(value => value.is_registration_open).some(value => value)
    },
    formattedDateRangeString(){
      return formatDate(this.event.date_from)+' - '+formatDate(this.event.date_to)
    }
  }
}
</script>

<style scoped lang="scss">
@import "./src/styles/themes";

.dot {
  height: 6px;
  width: 6px;
  margin-left: 12px;
  margin-right: 12px;
  border-radius: 8px;
  @include theme {
    background-color: theme-get('primary-color')
  }
}

.eventListElement {

  h3 {
    width: auto;
    line-height: 1.05;
    margin: 0;
    font-size: 2.25em;
    font-weight: 600;
    @include theme {
      color: theme-get('header-3')
    }
  }
  @include theme {
    background-color: theme-get(background-color-1);
    box-shadow: theme-get(shadow-1);
  }
  outline: transparent 2px solid;
  transition:0.25s ease-in-out;
  &:hover{
    @include theme{
      outline-color: theme-get(primary-color);
    }
    transform: scale(1.01);
    cursor: pointer;
  }


  width: 100%;
  border-radius: 16px;
  padding: 32px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {

    margin: 0;
    font-size: 1.125em;
    @include theme {
      color: theme-get('text-2')
    }

  }

  .details {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}


@media screen and (max-width: 768px){
  .eventListElement{

    text-align: center;
    .eventInfo{
      gap:32px;
      flex-direction: column;
    }
    gap:16px;
    flex-direction: column;
    .details{
      align-items: center;
      text-align: center;
      flex-direction: column;
      div{
        flex-direction: column;
        gap:8px;
      }
      .dot{
        display:none;
      }
    }
  }
}
@media screen and (max-width: 512px){
  .eventListElement{

    h3{
      font-size: 2em;
    }
  }
}
</style>
