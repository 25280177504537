<template>
  <div class="loadingContainer">
    <LoadingIndicator></LoadingIndicator>
  </div>
</template>

<script>
import LoadingIndicator from "@/components/Indicators/Loading/LoadingIndicator";
export default {
  name: "FullscreenLoading",
  components: {LoadingIndicator},
}
</script>

<style lang="scss" scoped>
@import "src/styles/themes";

  .loadingContainer{
    z-index: 95;
    position: fixed;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    @include theme(){
      background-color: theme-get('background-overlay');
    }
  }
</style>
